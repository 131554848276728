import { commonServer } from "@/utils/http.js";

// 获取企业微信Config
export const getWxConfig = (obj) => {
  return commonServer({
    method: "get",
    url: "/get_sign_package?url=" + obj.url + "&corp_id=" + obj.corp_id,
  });
};
// 调用微信授权
export const invokeWeChatAuth = (obj) => {
  return commonServer({
    method: "get",
    url: "/get_code?url=" + obj.url,
  });
};
