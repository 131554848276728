import router from "./index";
import { getStore } from "@/utils/storage";
import { getUser, setUser } from "@/utils/weixin";

router.beforeEach(async (to, from, next) => {
  // 添加动态title
  window.document.title = to.name;
  // 判断是否存在微信openid
  const userid = getStore("userid");
  const name = getStore("name");
  const corp_id = getStore("corp_id");
  if (!userid || !name || !corp_id) {
    await getUser();
    if (to.query && to.query.user_id) {
      next({ path: "/warranty" });
    } else {
      next();
    }
  } else {
    setUser({ userid: userid, name: name, corp_id: corp_id });
    if (to.query && to.query.user_id) {
      next({ path: "/warranty" });
    } else {
      next();
    }
  }
});

router.afterEach(async (to) => {
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title;
  }
});
