import { setStore } from "@/utils/storage";

const state = {
  name: "",
  avatar: "",
  openid: "",
  userid: "",
};

const mutations = {
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_OPENID: (state, openid) => {
    state.openid = openid;
  },
  SET_USERID: (state, userid) => {
    state.userid = userid;
  },
  SET_CORPID: (state, corp_id) => {
    state.corp_id = corp_id;
  },
};

const actions = {
  setUserOpenid({ commit }, userInfo) {
    const openid = userInfo.openid;
    return new Promise((resolve) => {
      setStore("openid", openid);
      commit("SET_OPENID", openid);
      resolve();
    });
  },
  setUserInfo({ commit }, userInfo) {
    const { userid, name, corp_id } = userInfo;
    return new Promise((resolve) => {
      setStore("userid", userid);
      commit("SET_USERID", userid);
      setStore("name", name);
      commit("SET_NAME", name);
      setStore("corp_id", corp_id);
      commit("SET_CORPID", corp_id);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
