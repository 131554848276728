import store from "@/store/index.js";
import { invokeWeChatAuth } from "@/api/weixin";

/**
 * 获取页面参数-常规
 */
const getUrlCode = () => {
  let url = decodeURI(location.search);
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.substr(1);
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
};
/**
 * 获取页面参数-HASH
 */
const getQueryVariable = () => {
  const url = decodeURI(window.location.hash).split("?", 2)[1];
  let theRequest = new Object();
  if (url) {
    let strs = url.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
};
/**
 * 获取微信code
 */
export function getCode() {
  let code = "";
  const local = window.location.href; // 获取页面url
  const appid = store.getters.appid;
  code = getQueryVariable().code; // 截取code
  if (code == null || code === "") {
    // 如果没有code，则去请求
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
      local
    )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
  } else {
    // 你自己的业务逻辑
    store.dispatch("user/getUserInfo", { code: code });
  }
}
/**
 * 获取微信用户信息
 */
export async function getUser() {
  const local = window.location.href;
  const ua = navigator.userAgent.toLowerCase();
  let url = "";
  if (/iphone|ipad|ipod/.test(ua)) {
    url = local.split("#")[0];
  } else if (/android/.test(ua)) {
    url = local;
  }
  const urlCode = getUrlCode();
  const userid = urlCode.user_id;
  const name = urlCode.name;
  const corp_id = urlCode.corp_id;
  if (userid == null || userid === "") {
    // openid不存在，则去请求调用授权
    await invokeWeChatAuth({ url: url });
  } else {
    // openid存在，则表示用户已授权，页面缓存openid避免重复授权
    store.dispatch("user/setUserInfo", {
      userid: userid,
      name: name,
      corp_id: corp_id,
    });
  }
}
/**
 * 初始化微信用户信息
 */
export function setUser(userInfo) {
  const urlCode = getUrlCode();
  const userid = urlCode.user_id;
  const name = urlCode.name;
  const corp_id = urlCode.corp_id;
  if (userid && name && corp_id) {
    store.dispatch("user/setUserInfo", {
      userid: userid,
      name: name,
      corp_id: corp_id,
    });
  } else {
    store.dispatch("user/setUserInfo", {
      userid: userInfo.userid,
      name: userInfo.name,
      corp_id: userInfo.corp_id,
    });
  }
}
