/**
 * 存储localStorage
 */
export function setStore(name, content) {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export function getStore(name) {
  if (!name) return;
  return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
export function removeStore(name) {
  if (!name) return;
  window.localStorage.removeItem(name);
}

/**
 * 设置cookie
 **/
export function setCookie(name, value, day) {
  let date = new Date();
  date.setDate(date.getDate() + day);
  document.cookie = name + "=" + value + ";expires=" + date;
}

/**
 * 获取cookie
 **/
export function getCookie(name) {
  let reg = RegExp(name + "=([^;]+)");
  let arr = document.cookie.match(reg);
  if (arr) {
    return arr[1];
  } else {
    return "";
  }
}

/**
 * 删除cookie
 **/
export function delCookie(name) {
  setCookie(name, null, -1);
}
