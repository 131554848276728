/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "",
    redirect: "/warranty",
  },
  {
    path: "",
    name: "",
    redirect: "/warranty",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/200",
    name: "200",
    meta: { title: "异常", requireAuth: true },
    component: () => import("@/components/error/200.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "异常", requireAuth: true },
    component: () => import("@/components/error/404.vue"),
  },
  {
    path: "/warranty",
    name: "Warranty",
    meta: { title: "质保查询", requireAuth: true },
    component: () => import("@/views/warranty/index.vue"),
    children: [
      {
        path: "details",
        name: "Details",
        meta: { title: "质保查询结果", requireAuth: true },
        component: () => import("@/views/warranty/details.vue"),
      },
      {
        path: "apply",
        name: "Apply",
        meta: { title: "申请延保", requireAuth: true },
        component: () => import("@/views/warranty/apply.vue"),
      },
      {
        path: "list",
        name: "List",
        meta: { title: "我的延保", requireAuth: true },
        component: () => import("@/views/warranty/list.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("@/components/error/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  // history: createWebHashHistory(),
  routes,
});

export default router;
