<template>
  <div id="app">
    <suspense>
      <router-view />
    </suspense>
  </div>
</template>

<style lang="scss">
#app {
  height: 100%;
}
</style>
